import { OrgUser } from 'types/org_user'
import { persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface OrgUserStore {
  user: OrgUser
  setUser: (u: OrgUser) => void
  orgId: () => string
}

const useOrgUserStore = createWithEqualityFn<OrgUserStore>()(
  persist(
    (set, get) => ({
      user: { phone: '' } as OrgUser,
      setUser: (u) => set({ user: u }),
      orgId: () => get().user.currentRole?.orgId || ''
    }),

    {
      name: 'mobile:org_user',
      version: 1,
      migrate: (persistedState, version) => {
        return { user: { phone: '' } as OrgUser }
      }
    }
  ),
  shallow
)

export default useOrgUserStore
