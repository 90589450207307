import 'animate.css'
import { ConfigProvider } from 'antd-mobile'
import zhCN from 'antd-mobile/es/locales/zh-CN'
import 'dayjs/locale/zh-cn'
import { getx } from 'lib/axios'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { Middleware, SWRConfig, SWRHook } from 'swr'
import './app.css'
import './index.css'
import ReloadPrompt from './reload'
import { router } from './router'
import './transition.css'
import Version from './version'
const logger: Middleware = (useSWRNext: SWRHook) => {
  return (key, fetcher, config) => {
    // 将日志记录器添加到原始 fetcher。
    const extendedFetcher = (...args) => {
      // console.log('SWR Request:', key, fetcher, '123', config, '123', args)
      return fetcher!(...args)
    }

    // 使用新的 fetcher 执行 hook。
    return useSWRNext(key, extendedFetcher, config)
  }
}
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ConfigProvider locale={zhCN}>
    <SWRConfig value={{ fetcher: getx, use: [logger] }}>
      <RouterProvider router={router} />
      {/* <RouterProvider router={router} /> */}
      <ReloadPrompt />
      <Version />
    </SWRConfig>
  </ConfigProvider>
)
