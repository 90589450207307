import { SpinLoading } from 'antd-mobile'
import { ClockSvg, HouseFill, ProductFill, UsersFill } from 'components/icons'
import Login from 'pages/login'
import { ReactNode, Suspense, lazy } from 'react'
import { RouteObject, createBrowserRouter } from 'react-router-dom'

export const LazyPage = (p: Promise<any>): ReactNode => {
  const Component = lazy(() => p)
  return (
    <Suspense
      fallback={
        <div className="p-6">
          <SpinLoading />
        </div>
      }
    >
      <Component />
    </Suspense>
  )
}
export interface NavItem {
  label: string
  roles?: number[]
  type?: string
  icon?: ReactNode
  path?: string
  element?: ReactNode
  children?: NavItem[]
  hide?: boolean
}
export const items: NavItem[] = [
  {
    label: '首页',
    path: '/',
    icon: <HouseFill />,
    // element: <HomePage />,
    element: LazyPage(import('../pages/home'))
  },
  {
    label: '预约中心',
    path: 'appointment',
    icon: <ClockSvg className="w-5 h-5" />,
    element: LazyPage(import('../pages/appointment'))
  },
  {
    icon: <UsersFill />,
    label: '改造任务',
    path: 'tasks',
    // element: LazyLoadComponent('tasks'),
    // element: <TaskPage />,
    element: LazyPage(import('../pages/tasks'))
  },
  {
    icon: <ProductFill />,
    label: '产品目录',
    path: 'products',
    element: LazyPage(import('../pages/product_show'))
  }
  // {
  //   icon: <ProductFill />,
  //   label: '地图',
  //   path: 'map',
  //   // element: LazyLoadComponent('tasks'),
  //   element: <MapPage />,
  // },
]
// import withLazyLoad from '../lib/HOC/lazy'
export const hiddenItems: NavItem[] = [
  {
    hide: true,
    label: '项目详情',
    path: 'detail',
    element: LazyPage(import('../pages/task_detail'))
  },

  {
    label: '评估编辑器',
    path: 'assess_editor',
    element: LazyPage(import('../pages/assess/editor'))
  },

  {
    label: '评估结果',
    path: 'assess_result',
    element: LazyPage(import('../pages/assess/result'))
  },

  // {
  //   label: '方案列表',
  //   path: 'solution',
  //   element: <SolutionPage />,
  // },
  // {
  //   label: '方案编辑',
  //   path: 'solution/edit',
  //   element: <SolutionEditPage />,
  // },
  {
    label: '拍照',
    path: 'photo',
    element: LazyPage(import('../pages/photo'))
    // element: <SolutionPhotoPage />,
  },
  {
    label: '设计需求',
    path: 'design_requirement',
    element: LazyPage(import('../pages/design_requirement'))
  },
  {
    label: '设计结果',
    path: 'design_result',
    element: LazyPage(import('../pages/design_result'))
  },
  {
    label: '产品',
    path: 'design_products',
    element: LazyPage(import('../pages/design_products'))
    // element: <DesignProductsPage />,
  },
  {
    label: '设计工具',
    path: 'tool',
    element: LazyPage(import('../pages/design_tool'))
  },
  {
    label: '产品详情',
    path: 'product',
    element: LazyPage(import('../pages/product'))
    // element: <ProductPage />,
  },

  {
    label: '购买页',
    path: 'purchase',
    element: LazyPage(import('../pages/purchase'))
  },
  {
    label: '施工页',
    path: 'construct',
    // element: <ConstructionPage />,
    element: LazyPage(import('../pages/construct'))
  },

  {
    label: '验收页',
    path: 'check',
    element: LazyPage(import('../pages/check'))
    // element: <CheckPage />,
  },
  {
    label: '帮助页',
    path: 'help',
    element: LazyPage(import('../pages/help'))
    // element: <HelpPage />,
  },
  {
    label: '安装帮助',
    path: 'install',
    element: LazyPage(import('../pages/install_helper'))
  },
  {
    label: '个人页',
    path: 'user',
    element: LazyPage(import('../pages/user'))
  },
  {
    label: '切换角色',
    path: 'switch-role',
    element: LazyPage(import('../pages/switch-role'))
  },
  {
    label: '个人头像调整页',
    path: 'avatar_edit',
    element: LazyPage(import('../pages/user/avatar_edit'))
  },
  {
    label: '产品页-按照分类检索',
    path: 'product_category',
    element: LazyPage(import('../pages/product_show/category'))
  },
  {
    label: '图标',
    path: 'icons',
    element: LazyPage(import('../pages/icons'))
  }
]

const getRouterObjs = (items: NavItem[]) => {
  let objs: RouteObject[] = [
    {
      path: '/noproject',
      element: LazyPage(import('../components/no_project'))
    }
  ]

  // 侧边栏下的子静态路由
  items.forEach((item) => {
    let obj: RouteObject = {
      path: item.path,
      element: item.element
    }
    objs.push(obj)
  })
  return objs
}

// 创建Browser静态路由供provider消费。
export const router = createBrowserRouter([
  {
    path: '/',
    element: LazyPage(import('../pages/App')),
    children: [
      {
        path: '/',
        // element: < HomeIndex/>,
        element: LazyPage(import('../pages')),
        children: getRouterObjs(items)
      },
      {
        path: '/login',
        element: <Login />
      },
      // 静态 功能页面
      ...getRouterObjs(hiddenItems)
    ]
  }
])
